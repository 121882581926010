body > iframe[name='wealthica-connect-widget'] {
  pointer-events: none;
}

:root {
  --sidebar-padding-top: 28px;
  --content-padding-top: 28px;
}

.sidebar-navigation {
  padding-top: var(--sidebar-padding-top);
  transition: padding-top 0.3s ease;
}

.main-content {
  padding-top: var(--content-padding-top);
  transition: padding-top 0.3s ease;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

.ReactModal__Content,
.ReactModal__Content--after-open {
  border-radius: 0 !important;
}

::-webkit-scrollbar {
  -webkit-border-radius: 5px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b3b1b1;
}

.MuiDrawer-root .MuiDrawer-paper {
  margin-top: var(--sidebar-padding-top);
  transition: margin-top 0.3s ease;
}
